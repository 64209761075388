import React, { useEffect, useState, useMemo } from 'react';
import TranscendCreate from './TranscendCreate';
import TranscendSpeakers from './TranscendSpeakers';
import TranscendTranscribe from './TranscendTranscribe';
import TranscendTranslate from './TranscendTranslate';
import AudioSegments from './AudioSegments';
import DubbedVideo from './DubbedVideo';
import TranscendHome from './TranscendHome';
import SpectralSyncVideo from './SpectralSyncVideo';
import { Routes, Route, useLocation } from 'react-router-dom';

export default function SpectralTranscend({
  setSearchText,
  searchText,
  setUserInfo,
  selectedTranscendComponent,
  setSelectedTranscendComponent,
  menuLock,
  setMenuLock,
  projectName,
  setProjectName,
}) {
  const [projectId, setProjectId] = useState('');
  const projectIdLc = projectId.toLowerCase();
  const [languageId, setLanguageId] = useState('');
  const [speakersList, setSpeakersList] = useState([]);
  const [videoUrl, setVideoUrl] = useState('');
  const [selectedLangArray, setSelectedLangArray] = useState([]);
  const [voice1, setVoice1] = useState({});
  const [voicesArray, setVoicesArray] = useState([]);
  const [speakers, setSpeakers] = useState([]);
  const apiURL = process.env.REACT_APP_API_URL;
  const location = useLocation();

  useEffect(() => {
    document.title = 'Transcend - Spectral Studio';
    setSearchText('');

    // Cleanup function to reset search text when component unmounts
    return () => {
      setSearchText(''); // Resetting state on unmount
    };
  }, []);

  useEffect(() => {
    if (location.pathname !== '/transcend') {
      setMenuLock(true);
    } else {
      setMenuLock(false);
    }
  }, [location.pathname]);

  const commonProps = {
    searchText,
    setSelectedTranscendComponent,
    setUserInfo,
    menuLock,
    setMenuLock,
    projectId,
    setProjectId,
    languageId,
    setLanguageId,
    videoUrl,
    setVideoUrl,
    projectName,
    setProjectName,
    selectedLangArray,
    setSelectedLangArray,
    voice1,
    setVoice1,
    voicesArray,
    setVoicesArray,
    speakersList,
    setSpeakersList,
    speakers,
    setSpeakers,
  };

  return (
    <div className="spectralTranscend">
      {(location.pathname === '/transcend' ||
        location.pathname === '/transcend/home') && (
        <>
          <h2 className="mt-4 ms-5">Spectral Transcend</h2>
          <span className="mt-2 ms-5" style={{ color: 'grey' }}>
            Fully flexible end-to-end localization workflow for powerful,
            dynamic, and human-in-the-loop localization.
          </span>
        </>
      )}
      <div>
        <Routes>
          <Route path="/" element={<TranscendHome {...commonProps} />} />
          <Route path="home" element={<TranscendHome {...commonProps} />} />
          <Route
            path="create"
            element={
              <TranscendCreate
                {...commonProps}
                voice1={voice1}
                setVoice1={setVoice1}
                setSpeakers={setSpeakers}
              />
            }
          />
          <Route
            path=":projectIdLc/speaker"
            element={<TranscendSpeakers {...commonProps} speakers={speakers} />}
          />
          <Route
            path=":projectIdLc/transcribe"
            element={
              <TranscendTranscribe
                {...commonProps}
                speakersList={speakersList}
                setSpeakersList={setSpeakersList}
              />
            }
          />
          <Route
            path=":projectIdLc/translate"
            element={
              <TranscendTranslate
                {...commonProps}
                speakersList={speakersList}
                setSpeakersList={setSpeakersList}
              />
            }
          />
          <Route
            path=":projectIdLc/audio-segments"
            element={
              <AudioSegments {...commonProps} projectName={projectName} />
            }
          />
          <Route
            path=":projectIdLc/download"
            element={
              <DubbedVideo
                {...commonProps}
                voicesArray={voicesArray}
                setVoicesArray={setVoicesArray}
              />
            }
          />
          <Route
            path=":projectIdLc/sync"
            element={
              <SpectralSyncVideo
                {...commonProps}
                videoUrl={videoUrl}
                setVideoUrl={setVideoUrl}
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
}
