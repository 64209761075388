import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from 'react-bootstrap/Spinner';
import getBlobDuration from 'get-blob-duration';
import Form from 'react-bootstrap/Form';
import CustomVideoPlayer from './CustomVideoPlayer';
import loading from '../../../multimedia/loading.gif';
import { FaUpload } from 'react-icons/fa6';

import Record from './Record';

export default function RecordAudio({
  handleRecordClose,
  segmentName,
  projectId,
  audioId,
  audioDuration,
  segmentData,
  videoUrl,
  setAudioSegments,
  languageId,
}) {
  const apiURL = process.env.REACT_APP_API_URL;
  const [audioBlob, setAudioBlob] = useState(null);
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [flag, setFlag] = useState(false);
  const [timer, setTimer] = useState(0);
  const [resetTimer, setResetTimer] = useState(true);
  const [seekTime, setSeekTime] = useState({ value: 0 });
  const [currentTime, setCurrentTime] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(100);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [loopTranscript, setLoopTranscript] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const videoRef = useRef(null);
  const [totalDuration, setTotalDuration] = useState(0);
  const [current, setCurrent] = useState('0:00.000');
  const [generatedUrl, setGeneratedUrl] = useState('');
  const [recordingUrl, setRecordingUrl] = useState(null);

  const [fetchedAudioUrl, setFetchedAudioUrl] = useState('');
  const fileInputRef = useRef(null);
  const [wait, setWait] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [trimmedUrl, setTrimmedUrl] = useState(null);
  const [showRecordPanel, setShowRecordPanel] = useState(false);
  const [trimDuration, setTrimDuration] = useState(0);
  const [isRegionResizable, setIsRegionResizable] = useState(true);
  const [allowGenerate, setAllowGenerate] = useState(false);
  const [allowResize, setAllowResize] = useState(false);

  const notify = (error) =>
    toast.error(error, {
      className: 'custom-error-toast',
    });

  useEffect(() => {
    let interval;
    if (recording) {
      // setTimer(0);
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 0.1); // Update timer every 0.1 second (100 milliseconds)
      }, 100); // Update every 100 milliseconds
    } else {
      clearInterval(interval);
      if (resetTimer) {
        setTimer(0);
      }
    }
    return () => clearInterval(interval);
  }, [recording, resetTimer]);

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    const milliseconds = Math.floor((time - Math.floor(time)) * 1000);

    const formattedMilliseconds = milliseconds
      .toString()
      .padStart(3, '0')
      .substring(0, 2);

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}.${formattedMilliseconds}`;
  };

  function handleLoop() {
    setLoopTranscript(!loopTranscript);
  }

  function uploadRecordedFile(selectedFile) {
    const formData = new FormData();
    formData.append('ProjectId', projectId);
    formData.append('filename', selectedFile.name);
    formData.append('file', selectedFile);
    // console.log(formData);
    fetch(`${apiURL}/util/file_upload_aws`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to fetch project data');
        }
      })
      .then((res) => {
        if (res.IsValid) {
          //  console.log(res.Url);
          saveAudio(res.Url);
        } else {
          notify('Upload unsuccessful, please upload a valid file!');
          console.error('Failed to upload file');
        }
      })
      .catch((error) => {
        setFlag(false);
        console.error('Error:', error);
      });
  }

  // Fetch audio segments api call

  const fetchAudioSegments = async () => {
    const reqBody = {
      ProjectId: projectId,
      TargetLanguageId: languageId,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_audio_segments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const newData = await response.json();
        if (newData.IsValid) {
          setAudioSegments([...(newData.ResultAudioSegments || [])]);
          setFlag(false);
          handleRecordClose();
        }
      } else {
        setFlag(false);
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      setFlag(false);
      console.error('Error:', error);
    }
  };

  function saveAudio(fileUrl) {
    const request = {
      ProjectId: projectId,
      TargetLangId: languageId,
      AudioSegmentId: audioId,
      FileName: segmentName,
      AudioSegmentUrl: fileUrl,
    };
    fetch(`${apiURL}/dub/replace_audio_segment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          setFlag(false);
          throw new Error('Failed to fetch project data');
        }
      })
      .then((newData) => {
        if (newData.IsValid) {
          fetchAudioSegments();
        }
      });
  }

  function handleSave() {
    if (audioBlob !== null) {
      setFlag(true);
      // console.log('file is',file);
      if (generatedUrl === '') {
        uploadRecordedFile(audioBlob);
      } else {
        // console.log("redirected here")
        saveAudio(generatedUrl);
      }
    } else if (audioBlob === null && generatedUrl !== '') {
      // console.log("redirected here")
      saveAudio(generatedUrl);
    } else {
      setFlag(false);
    }
  }

  useEffect(() => {
    if (
      !isNaN(segmentData.TranscriptStartTime) &&
      isFinite(segmentData.TranscriptStartTime)
    ) {
      setSeekTime({ value: segmentData.TranscriptStartTime });
    }
  }, [segmentData]);

  useEffect(() => {
    // code for looping transcript based on current time , index and loopTranscript boolean value
    if (loopTranscript && currentTime > segmentData.TranscriptEndTime) {
      setCurrentTime(segmentData.TranscriptStartTime);
      setSeekTime({ value: segmentData.TranscriptStartTime });
    }
  }, [currentTime, loopTranscript, segmentData]);

  useEffect(() => {
    var audio = document.getElementById('audio-recording');
    var timeDisplay = document.querySelector('#current-recording-time');
    var totalTimeDisplay = document.querySelector('#total-recording-time');
    if (audio && timeDisplay && totalTimeDisplay) {
      audio.addEventListener('timeupdate', function () {
        var currentTime = audio.currentTime;
        var duration = isNaN(audio.duration) ? 0 : audio.duration;
        var currentMinutes = Math.floor(currentTime / 60);
        var currentSeconds = Math.floor(currentTime - currentMinutes * 60);
        var currentMilliseconds = Math.floor(
          (currentTime - Math.floor(currentTime)) * 1000
        );
        var durationMinutes = Math.floor(duration / 60);
        var durationSeconds = Math.floor(duration - durationMinutes * 60);
        var durationMilliseconds = Math.floor(
          (duration - Math.floor(duration)) * 1000
        );

        var currentTimeString =
          currentMinutes +
          ':' +
          (currentSeconds < 10 ? '0' : '') +
          currentSeconds +
          '.' +
          (currentMilliseconds < 100 ? '0' : '') +
          (currentMilliseconds < 10 ? '0' : '') +
          currentMilliseconds;
        var durationString =
          durationMinutes +
          ':' +
          (durationSeconds < 10 ? '0' : '') +
          durationSeconds +
          '.' +
          (durationMilliseconds < 100 ? '0' : '') +
          (durationMilliseconds < 10 ? '0' : '') +
          durationMilliseconds;
        // console.log(currentTimeString,durationString)
        setCurrent(currentTimeString);
        // timeDisplay.innerHTML = currentTimeString;
        // totalTimeDisplay.innerHTML = durationString;
      });
    }
  }, [audioBlob, audioUrl]);

  function generate_sts(fileUrl) {
    const request = {
      ProjectId: projectId,
      TargetLangId: languageId,
      AudioSegmentId: audioId,
      FileName: segmentName,
      AudioSegmentUrl: fileUrl,
      Duration: totalDuration,
    };
    fetch(`${apiURL}/dub/generate_sts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          setWait(false);
          throw new Error('Failed to fetch project data');
        }
      })
      .then((newData) => {
        if (newData.IsValid) {
          setGeneratedUrl(newData.STSSegmentUrl);
          setAllowResize(false);
          setTotalDuration(newData.Duration);
          // setAllowGenerate(false);
          setAudioUrl(newData.STSSegmentUrl);
          setWait(false);
          setIsRegionResizable(false);
        }
      });
  }

  useEffect(() => {
    const processAudioFromUrl = async () => {
      const urlToUse = trimmedUrl || audioUrl; // Use trimmedUrl if it's not null, otherwise use audioUrl
      // Use trimmedUrl if it's not null, otherwise use audioUrl

      if (urlToUse && segmentName) {
        try {
          const response = await fetch(urlToUse);
          const audioBlob = await response.blob();
          audioBlob.name = segmentName; // Set the name property of the blob
          const durationInSeconds = await getBlobDuration(audioBlob);
          setTotalDuration(durationInSeconds);
          console.log(`Audio duration: ${durationInSeconds} seconds`);

          if (
            segmentData.TranscriptDuration !== null &&
            (trimDuration > segmentData.TranscriptDuration + 1 ||
              trimDuration < segmentData.TranscriptDuration - 1)
          ) {
            // If the difference is greater than 1 second, reset the file to null
            setAudioBlob(audioBlob);
            const errorMessage =
              'The duration of recording should be within threshold limit. Please try another recording.';
            setErrorMsg(errorMessage);
          } else {
            setAudioBlob(audioBlob);
            setErrorMsg(null);
          }
        } catch (error) {
          console.error('Error fetching audio:', error);
        }
      }
    };

    processAudioFromUrl();
  }, [audioUrl, trimmedUrl, segmentName, errorMsg]);

  useEffect(() => {
    if (
      segmentData.TranscriptDuration !== null &&
      (trimDuration > segmentData.TranscriptDuration + 1 ||
        trimDuration < segmentData.TranscriptDuration - 1)
    ) {
      const errorMessage =
        'The duration of recording should be within threshold limit. Please try another recording.';
      setErrorMsg(errorMessage);
    } else {
      setErrorMsg(null);
    }
  }, [trimDuration]);

  function generateSingleAudioSegment() {
    setWait(true);
    // if (fetchedAudioUrl === '') {
    const formData = new FormData();
    formData.append('ProjectId', projectId);
    formData.append('filename', segmentName);
    formData.append('file', audioBlob);
    // console.log(formData);
    fetch(`${apiURL}/util/file_upload_aws`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to fetch project data');
        }
      })
      .then((res) => {
        if (res.IsValid) {
          //  console.log(res.Url);
          setFetchedAudioUrl(res.Url);
          generate_sts(res.Url);
          setAudioBlob(audioBlob);
        } else {
          notify('Upload unsuccessful, please upload a valid file!');
          console.error('Failed to upload file');
          setWait(false);
        }
      })
      .catch((error) => {
        setFlag(false);
        console.error('Error:', error);
        setWait(false);
      });
    // } else {
    //   generate_sts(fetchedAudioUrl);
    // }
  }

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const audio = new Audio(URL.createObjectURL(selectedFile));

    audio.addEventListener('loadedmetadata', async () => {
      // const durationInSeconds = Math.floor(audio.duration);
      const durationInSeconds = await getBlobDuration(selectedFile);
      const timestamp = new Date().getTime();
      const fileNameWithTimestamp = `${timestamp}_${selectedFile.name}`;

      // Create a new File object with the updated name
      const updatedFile = new File([selectedFile], fileNameWithTimestamp, {
        type: selectedFile.type,
        lastModified: selectedFile.lastModified,
      });
      setAudioBlob(updatedFile);
      setAudioUrl(URL.createObjectURL(updatedFile));
      setAllowGenerate(true);
      console.log(audioUrl);
      console.log('pppppp');

      setTotalDuration(durationInSeconds);
    });
  };

  return (
    <div>
      <Modal.Body style={{}}>
        {/* <Container> */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="record-data">
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label style={{ fontWeight: 500 }}>
                Transcription:{' '}
              </Form.Label>
              <Form.Control
                as="textarea"
                style={{ resize: 'none' }}
                rows={3}
                value={segmentData.TranscriptText}
                readOnly
              />
              <Form.Label className="mt-3" style={{ fontWeight: 500 }}>
                Translation:{' '}
              </Form.Label>
              <Form.Control
                as="textarea"
                style={{ resize: 'none' }}
                rows={3}
                defaultValue={segmentData.Text}
                readOnly
              />
            </Form.Group>
            {!isNaN(segmentData.TranscriptStartTime) &&
            isFinite(segmentData.TranscriptStartTime) &&
            !isNaN(segmentData.TranscriptEndTime) &&
            isFinite(segmentData.TranscriptEndTime) ? (
              <div
                className="mt-2"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <span style={{ fontWeight: 600 }}>
                  Start:
                  <span className="ms-1" style={{ fontWeight: 400 }}>
                    {formatTime(segmentData.TranscriptStartTime)}
                  </span>
                </span>
                <span style={{ fontWeight: 600 }}>
                  End:
                  <span className="ms-1" style={{ fontWeight: 400 }}>
                    {formatTime(segmentData.TranscriptEndTime)}
                  </span>
                </span>
                <span style={{ fontWeight: 600 }}>
                  Duration:
                  <span className="ms-1" style={{ fontWeight: 400 }}>
                    {formatTime(segmentData.TranscriptDuration)}
                  </span>
                </span>
              </div>
            ) : null}
          </div>

          <div className="record-data-video">
            <CustomVideoPlayer
              videoUrl={videoUrl}
              videoRef={videoRef}
              currentTime={currentTime}
              setCurrentTime={setCurrentTime}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              seekTime={seekTime}
              handleLoop={handleLoop}
              loopTranscript={loopTranscript}
              zoomLevel={zoomLevel}
              setZoomLevel={setZoomLevel}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
          </div>
        </div>
        {/* </Container> */}
        {/* <Container> */}
        {audioUrl ? (
          <div style={{ width: '100%' }} className="record-div-play ">
            <div style={{ position: 'relative' }}>
              <Record
                recordingUrl={recordingUrl}
                setRecordingUrl={setRecordingUrl}
                segmentData={segmentData}
                showRecordPanel={showRecordPanel}
                setShowRecordPanel={setShowRecordPanel}
                setLoopTranscript={setLoopTranscript}
                formatTime={formatTime}
                totalDuration={totalDuration}
                setTotalDuration={setTotalDuration}
                audioUrl={audioUrl}
                setAudioUrl={setAudioUrl}
                trimmedUrl={trimmedUrl}
                setTrimmedUrl={setTrimmedUrl}
                trimDuration={trimDuration}
                setTrimDuration={setTrimDuration}
                isRegionResizable={isRegionResizable}
                setIsRegionResizable={setIsRegionResizable}
                allowGenerate={allowGenerate}
                setAllowGenerate={setAllowGenerate}
                allowResize={allowResize}
                setAllowResize={setAllowResize}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  right: '0px',
                  bottom: '0px',
                }}
              >
                {wait === false ? (
                  <Button
                    className=""
                    variant="success"
                    disabled={!allowGenerate}
                    onClick={(e) => generateSingleAudioSegment()}
                  >
                    Generate
                  </Button>
                ) : (
                  <div
                    className="mt-3"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Button variant="secondary" disabled>
                      Generate
                    </Button>
                    <span>
                      <img
                        style={{
                          width: '40px',
                          height: '40px',
                          marginLeft: '10px',
                        }}
                        src={loading}
                        alt="loading"
                      />
                    </span>
                  </div>
                )}
              </div>
            </div>
            {/* <Button
              style={{ position: 'absolute', top: 15, right: 15 }}
              variant="text"
              onClick={() => deleteRecording()}
            >
              <MdOutlineClear />
            </Button> */}

            <span
              style={{
                position: 'absolute',
                top: '22px',
                left: '50%',
                transform: 'translate(-3%, 0)',
              }}
            >
              {' '}
              {errorMsg !== '' ? (
                <p
                  className=" text-center"
                  style={{ color: 'red', paddingBottom: '1px' }}
                >
                  {errorMsg}
                </p>
              ) : null}
            </span>
          </div>
        ) : (
          <div className="record-div-play">
            <div style={{ position: 'relative' }}>
              <div className="">
                {' '}
                <Record
                  recordingUrl={recordingUrl}
                  setRecordingUrl={setRecordingUrl}
                  segmentData={segmentData}
                  formatTime={formatTime}
                  setLoopTranscript={setLoopTranscript}
                  totalDuration={totalDuration}
                  setTotalDuration={setTotalDuration}
                  audioBlob={audioBlob}
                  setAudioBlob={setAudioBlob}
                  allowGenerate={allowGenerate}
                  setAllowGenerate={setAllowGenerate}
                  showRecordPanel={showRecordPanel}
                  setShowRecordPanel={setShowRecordPanel}
                  audioUrl={audioUrl}
                  setAudioUrl={setAudioUrl}
                  segmentName={segmentName}
                  trimmedUrl={trimmedUrl}
                  setTrimmedUrl={setTrimmedUrl}
                  trimDuration={trimDuration}
                  setTrimDuration={setTrimDuration}
                  isRegionResizable={isRegionResizable}
                  setIsRegionResizable={setIsRegionResizable}
                  allowResize={allowResize}
                  setAllowResize={setAllowResize}
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  right: '0px',
                  bottom: '0px',
                }}
              >
                {recording && <span>{formatTime(timer)}</span>}
                {!recording ? (
                  <Button variant="outline-dark" onClick={handleUploadClick}>
                    <span>
                      <FaUpload />
                    </span>{' '}
                    Upload
                  </Button>
                ) : null}
                <input
                  ref={fileInputRef}
                  type="file"
                  style={{ display: 'none' }}
                  accept=".mp3, .wav, .flac"
                  onChange={handleFileChange}
                />
              </div>

              {/* {!recording ? (
              <Button
                variant="outline-dark"
                onClick={(e) => {
                  setAudioUrl(segmentData.AudioSegmentUrl);
                  setFetchedAudioUrl(segmentData.AudioSegmentUrl);
                  setTotalDuration(segmentData.Duration);
                }}
              >
                <span>
                  <IoRecording />
                </span>{' '}
                Use current
              </Button>
            ) : null} */}
            </div>
          </div>
        )}

        {/* </Container> */}
      </Modal.Body>
      {flag === false ? (
        <Modal.Footer>
          <Button variant="secondary" onClick={handleRecordClose}>
            Close
          </Button>
          {audioBlob !== null || generatedUrl !== '' ? (
            <Button variant="primary" onClick={(e) => handleSave()}>
              Save
            </Button>
          ) : (
            <Button variant="secondary" disabled>
              Save
            </Button>
          )}
        </Modal.Footer>
      ) : (
        <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            className="mt-2 mb-2"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <span
              style={{ marginRight: '10px', fontSize: '14px', fontWeight: 500 }}
            >
              File upload in progress{' '}
            </span>{' '}
            <Spinner size="sm" animation="border" variant="success" />
          </div>
        </Modal.Footer>
      )}
      <ToastContainer />
    </div>
  );
}
